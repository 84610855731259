html {
  [color-palette='doctor-1'],
  [color-palette='doctor-2'],
  [color-palette='doctor-3'] {
    --palette-strong-1: #293241;
    --palette-strong-1-hover: #1b2433;
    --palette-strong-2: #3d5a80;
    --palette-strong-2-hover: #274164;
    --palette-light-1: #98c1d9;
    --palette-light-1-hover: #6fa3c1;
    --palette-light-2: #d3f4fc;
    --palette-light-2-hover: #a8d3dd;
    --palette-alternative-1: #ee6c4d;
    --palette-alternative-1-hover: #dc6142;
  }

  [color-palette='doctor-1'] {
    //general
    --background-color: var(--palette-light-2);
    --font-color: var(--palette-strong-2);
    --scrollbar-color: var(--palette-light-2);
    background-color: var(--background-color);
    color: var(--font-color);
  }

  [color-palette='doctor-2'] {
    //general
    --background-color: var(--palette-strong-1);
    --font-color: var(--palette-light-1);
    --scrollbar-color: var(--palette-light-2);

    background-color: var(--background-color);
    color: var(--font-color);
  }

  [color-palette='doctor-3'] {
    //general
    --background-color: var(--palette-strong-2);
    --font-color: var(--palette-light-2);
    --scrollbar-color: var(--palette-light-2);

    background-color: var(--background-color);
    color: var(--font-color);
  }
}
