@import '../../assets/styles/index.scss';

.root {
  position: fixed;
  bottom: 0;
  right: rem(32);
  z-index: 100000;
}

@media ($tablet-down) {
  .root {
    right: rem(24);
  }
}
