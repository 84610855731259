@import '../../../assets/styles/index.scss';

.root {
  list-style: none;
  display: flex;
  justify-content: center;
  .button {
    display: flex;
    justify-content: center;
    padding: rem(8);
    font-size: rem(10);
    border-radius: rem(2);
    text-wrap: wrap;
    text-align: start;
    border: 0;
    background-color: var(--gray-1);
    height: rem(49);

    .heading {
      text-align: center;
      margin: 0;
    }

    .hour {
      padding-top: rem(4);

      &.unique {
        padding-top: 0;
      }
    }

    &.selected {
      background-color: var(--palette-strong-2);
      color: var(--white);

      .heading {
        color: var(--white);
      }
    }
  }
}
