@import '../../assets/styles/index.scss';

.root {
  --button-primary-background: transparent;

  display: flex;
  justify-content: center;
  border: none !important;
  padding: 0 !important;
  width: rem(40);
  height: rem(40);
  margin-right: 0;

  svg {
    transition: width 0.5s, height 0.5s;
    width: rem(24);
    height: rem(24);
  }

  &:active {
    background-color: transparent;
  }

  &:hover {
    --button-primary-background-hover: transparent;
    svg {
      transition: width 0.5s, height 0.5s;
      width: rem(28);
      height: rem(28);
    }
  }
}
