@import '../../../assets/styles/index.scss';

.root {
  width: 100%;
  padding-left: rem(32);
  box-sizing: border-box;

  .title {
    margin: 0;
    margin-bottom: rem(32);
  }

  .articles {
    padding: 0;

    .article {
      list-style: none;
      width: 100%;
      margin-bottom: rem(32);

      img {
        width: 100%;
        object-fit: fill;
        cursor: pointer;
      }

      h3 {
        margin: 0;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

@media ($tablet-down) {
  .root {
    padding: 0;
    .articles {
      display: flex;
      height: auto;
      overflow: hidden;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 0 !important;
      }

      .article {
        height: 100%;
        width: auto;
        margin-right: rem(16);
        img {
          height: rem(150);
          width: auto;
        }
      }
    }
  }
}
