html {
  --background: #f5f5f5;

  //START OF UNSUPPORTED COLORS
  // this colors are going to be unsupported in future versions. Try to don't use it and remove
  // them from actual components

  --primary-fit-color-deg: #048edd;

  --primary-fit-color: #0f4a82;
  --primary-for-color: #000;
  --primary-compete-color: #880808;

  --success-hover: #c5ffc5;
  --compete-hover: #fcdcdc;
  --fit-hover: #deedfb;
  --for-hover: var(--gray-1);

  --compete-active: #fbc5c5;
  --fit-active: #c8e1f8;
  --for-active: var(--gray-2);

  //optional colors

  --obsidian: #3b363c;
  --diamont: #b9f2ff;
  --diamont-2: #85d3e5;
  --gold: #ffbf00;
  --silver: #b8b9ba;
  --bronce: #b87333;

  --warning: #ffd400;
  --success: #53a653;

  // END OF UNSUPPORTED COLORS

  /**
    * Gray scale for text, shadows, etc...
    */

  --white: #ffffff;
  --gray-1: #eeeeee;
  --gray-2: #cccccc;
  --gray-3: #999999;
  --gray-4: #666666;
  --gray-5: #333333;
  --black: #000000;

  --icon-color: #0f4a82;

  //buttons colors

  --background-color: #f5f5f5;
  --font-color: var(--gray-5);

  background-color: var(--background-color);
  color: var(--font-color);

  // Palette vars
  --palette-strong-1: #293241;
  --palette-strong-1-hover: #1b2433;
  --palette-strong-2: #3d5a80;
  --palette-strong-2-hover: #274164;
  --palette-light-1: #98c1d9;
  --palette-light-1-hover: #6fa3c1;
  --palette-light-2: #d3f4fc;
  --palette-light-2-hover: #a8d3dd;
  --palette-alternative-1: #ee6c4d;
  --palette-alternative-1-hover: #dc6142;

  //success colors

  --success-background: #5cb85c;
  --success-background-hover: #53a653;
  --success-background-active: #4a934a;
  --success-color: var(--white);

  //warning colors

  --warning-background: #ffaa71;
  --warning-background-hover: #ff875a;
  --warning-background-active: #fb6944;
  --warning-color: var(--white);

  //error colors

  --error-background: #ef5350;
  --error-background-hover: #f44336;
  --error-background-active: #e53935;
  --error-color: var(--white);
}
