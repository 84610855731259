@import './assets/styles/index.scss';

body {
  margin: 0;
  min-height: 100vh;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

html {
  max-height: 100vh;

  * {
    scrollbar-color: var(--scrollbar-color) var(--scrollbar-background);
  }
}

[id='root'] {
  display: flex;
  min-width: 100vw;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

// @media ($tablet-down) {
//   [id='root'] {
//     height: auto;
//   }
// }
