@import '../../../../assets/styles/index.scss';

.root {
  padding: 0 rem(16);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  p {
    margin-right: rem(16);
  }
}
