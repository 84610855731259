@import '../../../assets/styles/index.scss';

.block {
  flex-direction: column;
  .title {
    margin: 0;
    margin-bottom: rem(32);
    font-size: rem(48);
  }

  .services-container {
    display: flex;
    flex-direction: column;
  }
}
