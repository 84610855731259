@import '../../../../assets/styles/index.scss';

.root {
  display: flex;
  width: 100%;

  .children {
    width: 100%;
  }

  .sidebar {
    border-left: rem(2) solid var(--white);
    width: rem(400);
    margin-left: rem(16);
  }
}

@media ($tablet-down) {
  .root {
    flex-direction: column;

    .sidebar {
      border-left: 0;
      margin-top: rem(16);
      padding-top: rem(16);
      width: 100%;
      margin-left: 0;
      border-top: rem(2) solid var(--white);
    }
  }
}
