html {
  [color-palette='doctor-1'] {
    --page-root-landing-title-color: var(--palette-strong-2);
    --page-root-landing-heading-border-color: var(--palette-strong-2);
  }

  [color-palette='doctor-2'] {
    --page-root-landing-title-color: var(--palette-light-2);
    --page-root-landing-heading-border-color: var(--palette-strong-2);
  }
}
