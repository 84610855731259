html {
  [color-palette='doctor-1'] {
    [react-component='service-card'] {
      --service-card-background: var(--palette-strong-1);
      --service-card-color: var(--palette-light-2);
      --button-secondary-background: var(--palette-light-2);
      --button-secondary-background-hover: var(--palette-light-2);
      --button-secondary-color: var(--palette-strong-2);
      --button-primary-background: var(--palette-light-2);
      --button-primary-background-hover: var(--palette-light-2);
      --button-primary-border: var(--palette-light-2);
      --button-primary-color: var(--palette-light-2);
    }
  }

  [color-palette='doctor-2'] {
    --service-card-background: var(--palette-light-2);
    --service-card-color: var(--palette-strong-2);
  }

  [color-palette='doctor-3'] {
    --service-card-background: var(--palette-light-2);
    --service-card-color: var(--palette-strong-2);
  }

  [color-palette='dentist-1'] {
    --service-card-background: var(--palette-strong-1);
    --service-card-color: var(--white);
  }
}
