html {
  [color-palette='doctor-1'] {
    --navbar-item-color: var(--palette-strong-1);
    --navbar-item-color-background-invisible: var(--palette-light-2);
  }

  [color-palette='doctor-2'] {
    --navbar-item-color: var(--palette-strong-1);
    --navbar-item-color-background-invisible: var(--palette-light-2);
  }

  [color-palette='doctor-3'] {
    --navbar-item-color: var(--palette-strong-1);
    --navbar-item-color-background-invisible: var(--palette-light-2);
  }

  [color-palette='dentist-3'] {
    --navbar-item-color: var(--palette-strong-1);
    --navbar-item-color-background-invisible: var(--palette-strong-1);
  }
}
