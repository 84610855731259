@import '../../assets/styles/index.scss';

.title {
  display: inline-block;
  justify-content: center;
  text-align: center;
  border: 1px solid var(--gray-4);
  border-radius: rem(2);
  padding: rem(3);
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  span {
    margin-right: rem(6);
  }

  :first-child {
    color: var(--gray-5);
  }

  &.xs {
    font-size: rem(16);

    span {
      margin-right: rem(4);
    }
  }

  &.small {
    font-size: rem(24);

    span {
      margin-right: rem(6);
    }
  }

  &.medium {
    font-size: rem(32);

    span {
      margin-right: rem(8);
    }
  }

  &.large {
    font-size: rem(40);

    span {
      margin-right: rem(12);
    }
  }

  &.xl {
    font-size: rem(48);

    span {
      margin-right: rem(16);
    }
  }

  &.xxl {
    font-size: rem(64);

    span {
      margin-right: rem(20);
    }
  }

  &.no-border {
    border: none;
  }
}
