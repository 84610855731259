@import '../../../../assets/styles/index.scss';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  list-style: none;
  padding: rem(16);
  background-color: var(--palette-strong-1);
  border-radius: rem(8);
  transition: background-color 0.3s, box-shadow 0.5s;
  cursor: pointer;

  img {
    border-radius: rem(4);
    height: 80%;
    object-fit: cover;
  }

  h3 {
    text-align: center;
    margin: 0;
    margin-top: rem(16);
    color: var(--palette-light-2);
  }

  span {
    bottom: rem(-16);
    right: rem(-16);
    background-color: var(--palette-light-2-hover);
    color: var(--palette-strong-1);
    padding: rem(4) rem(8);
    position: absolute;
    border-radius: rem(2);
    box-shadow: 0 0 rem(3) rem(1) rgba(0, 0, 0, 0.1);
    content: 'New';
  }

  &:hover {
    background-color: var(--palette-strong-1-hover);
    box-shadow: 0 rem(2) rem(3) rem(2) rgba(0, 0, 0, 0.1);
  }
}

@media ($tablet-down) {
  .root {
    h3 {
      margin-top: rem(8);
      font-size: rem(16);
    }

    span {
      font-size: rem(12);
      right: rem(-12);
    }
  }
}

@media (max-width: 500px) {
  .root {
    h3 {
      margin-top: rem(8);
      font-size: rem(18);
    }
  }
}
