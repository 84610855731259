@import '../../../../assets/styles/index.scss';

.root {
  svg {
    --icon-color: var(--gold);
    fill: var(--gold);
    margin-right: rem(10);

    &:last-child {
      margin-right: 0;
    }
  }
}
