@import '../../../assets/styles/index.scss';

.root {
  flex-direction: column;
  .title {
    margin-top: rem(16);
    font-size: rem(48);
  }

  li,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    display: grid;
    gap: rem(32);
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ($tablet-down) {
    .title {
      font-size: rem(40);
    }

    ul {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 500px) {
    ul {
      grid-template-columns: 1fr;
    }
  }
}
