@import '../../assets/styles/index.scss';

h1,
h2,
h3,
h4,
h5 {
  font-weight: 100;
  color: var(--heading-color);

  &.left {
    position: relative;
    padding-left: rem(16);
    border-left: rem(4) solid var(--heading-decoration-color);
  }

  &.under {
    position: relative;
    padding-bottom: rem(16);

    &::after {
      display: flex;
      position: absolute;
      content: '';
      width: rem(60);
      height: rem(4);
      background-color: var(--heading-decoration-color);
      bottom: 0;
    }
  }
}
