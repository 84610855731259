@import '../../assets/styles/index.scss';

.root {
  background-color: white;
  width: rem(280);
  border-radius: rem(4);
  //Remove after
  margin-top: rem(16);

  .day {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: rem(32);
    font-size: rem(16);
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem(32);
    border-bottom: 1px solid var(--gray-1);
    border-top: 1px solid var(--gray-1);

    .back-button {
      height: 100%;
      min-width: rem(40);
      margin: 0;
      border: 0;
      border-right: 1px solid var(--gray-1);

      svg {
        width: rem(16);
        height: rem(16);
      }
    }

    .heading {
      margin: 0;
      text-align: center;
      font-size: rem(14);
      width: 100%;
    }
  }

  .body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: rem(12);
    margin: 0;
    padding: rem(8);
  }
}
