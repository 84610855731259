html {
  [color-palette='doctor-1'] {
    --small-card-primary-color: var(--palette-strong-1);
    --small-card-primary-background: var(--white);
    --small-card-primary-border: var(--palette-strong-1);
    --small-card-primary-background-hover: var(--palette-light-1);

    --small-card-secondary-color: var(--white);
    --small-card-secondary-background: var(--palette-strong-1);
    --small-card-secondary-border: var(--palette-strong-1);
    --small-card-secondary-background-hover: var(--palette-strong-1-hover);
  }

  [color-palette='doctor-2'] {
    --small-card-primary-color: var(--palette-strong-2);
    --small-card-primary-background: var(--palette-light-2);
    --small-card-primary-border: var(--palette-strong-1);
    --small-card-primary-background-hover: var(--palette-light-1);

    --small-card-secondary-color: var(--white);
    --small-card-secondary-background: var(--palette-strong-2);
    --small-card-secondary-border: var(--palette-strong-1);
    --small-card-secondary-background-hover: var(--palette-strong-1-hover);
  }
}
