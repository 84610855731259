@import '../../../assets/styles/index.scss';

.root {
  display: flex;
  position: relative;

  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  height: rem(40);
  width: rem(40);
  list-style: none;
  margin: 0;
  color: var(--navbar-item-color);
  --icon-color: var(--navbar-item-color);

  &.background-invisible {
    color: var(--navbar-item-color-background-invisible);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);

    svg {
      height: rem(24);
      width: rem(24);
    }

    .notification {
      width: rem(18);
      height: rem(18);
      border-radius: rem(9);
    }

    & .name {
      width: fit-content;
      display: flex;
      opacity: 1;
    }
  }

  &.active {
    box-shadow: 0 0 rem(5) rem(3) rgba(0, 0, 0, 0.1);

    svg {
      width: rem(24);
      height: rem(24);
    }

    .notification {
      border-radius: rem(19);
      width: rem(18);
      height: rem(18);
    }
  }

  &.text {
    width: rem(120);
    padding: rem(4);
    box-sizing: border-box;
    font-weight: 500;
    text-align: center;
    font-size: rem(12);
    transition: background-color 0.3s;

    // &::after {
    //   content: '';
    //   background-color: var(--gray-2);
    //   position: absolute;
    //   width: rem(120);
    //   bottom: 0;
    //   height: 1px;
    //   z-index: inherit;
    // }

    &.active {
      box-shadow: none;
    }

    &:hover {
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  svg {
    width: rem(20);
    height: rem(20);
    transition: width 0.2s, height 0.2s;
  }

  .name {
    display: none;
    opacity: 0;
    border: rem(1) solid var(--gray-2);
    background-color: white;
    position: absolute;
    border-radius: rem(4);
    top: rem(50);
    min-height: rem(20);
    height: auto;
    padding: rem(5);
    animation: animateOpacity 0.2s linear;
    color: var(--gray-5);
    z-index: 10000;

    &::before {
      position: absolute;
      top: rem(-10);
      left: 0;
      background-color: black;
      width: rem(10);
      height: rem(10);
    }
  }

  .item-text {
    width: rem(100);
    max-width: none;
  }

  .notification {
    display: flex;
    position: absolute;
    bottom: rem(-10);
    opacity: 0.8;
    border-radius: rem(8);
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: rem(2);
    color: white;
    background-color: var(--primary-compete-color);
    width: rem(16);
    height: rem(16);
    font-size: rem(12);
    box-shadow: 0 0 rem(6) rem(1) rgba(0, 0, 0, 0.1);
    transition: width 0.2s, height 0.2s;
    z-index: 1000;
  }
}
