@import '../../../assets/styles/index.scss';

.root {
  justify-content: space-between;

  .hours {
    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      padding-bottom: rem(12);
    }
  }

  .hours,
  .direction {
    .title {
      text-align: center;
      font-size: rem(32);
      margin: 0;
      margin-bottom: rem(32);
    }
  }

  .direction {
    .map {
      min-width: rem(400);
      width: rem(400);
      height: rem(400);
      border-radius: rem(10);
      overflow: hidden;
    }
  }
}

@media ($tablet-down) {
  .root {
    flex-direction: column;

    .hours {
      margin-bottom: rem(64);
    }

    .title {
      font-size: rem(32);
      margin: 0;
      margin-bottom: rem(32);
    }

    .direction {
      .map {
        min-width: 0;
        width: 100%;
        height: rem(400);
      }
    }
  }
}
