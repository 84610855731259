@import '../../../assets/styles/index.scss';

.root {
  display: grid;
  grid-template-areas:
    'main main other other1'
    'main main other2 other3';
  gap: rem(32);
  grid-template-columns: repeat(4, calc(25% - rem(24)));
  grid-template-rows: rem(200) rem(200);
  grid-auto-rows: rem(200);
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  .newest {
    grid-area: main;
    justify-content: center;

    h3 {
      font-size: rem(28);
    }
  }

  .other {
    grid-area: other;
  }
}

@media ($tablet-down) {
  .root {
    grid-template-areas:
      'main main'
      'main main'
      'other other'
      'other other';
    grid-template-columns: repeat(2, calc(50% - rem(16)));
    grid-template-rows: rem(200) rem(200);
    grid-auto-rows: rem(200);
  }
}

@media (max-width: 500px) {
  .root {
    grid-template-areas:
      'main'
      'other'
      'other';
    grid-template-columns: 100%;
    grid-template-rows: rem(250);
    grid-auto-rows: rem(250);
  }
}
