html {
  --button-primary-color: var(--primary-fit-color);
  --button-primary-background: var(--white);
  --button-primary-border: var(--primary-fit-color);
  --button-primary-background-hover: var(--fit-hover);

  [color-palette='doctor-1'],
  [color-palette='doctor-2'],
  [color-palette='doctor-3'] {
    --button-primary-color: var(--palette-strong-1);
    --button-primary-background: var(--white);
    --button-primary-border: var(--palette-strong-1);
    --button-primary-background-hover: var(--gray-2);

    --button-secondary-color: var(--white);
    --button-secondary-background: var(--palette-strong-1);
    --button-secondary-border: var(--palette-strong-1);
    --button-secondary-background-hover: var(--palette-strong-1-hover);

    --button-tertiary-color: var(--white);
    --button-tertiary-background: var(--palette-strong-1);
    --button-tertiary-border: var(--palette-strong-1);
    --button-tertiary-background-hover: var(--palette-strong-1-hover);
  }

  [color-palette='dentist-1'],
  [color-palette='dentist-3'] {
    --button-primary-color: var(--palette-strong-1);
    --button-primary-background: var(--white);
    --button-primary-border: var(--palette-strong-1);
    --button-primary-background-hover: var(--gray-1);

    --button-secondary-color: var(--white);
    --button-secondary-background: var(--palette-strong-1);
    --button-secondary-border: var(--palette-strong-1);
    --button-secondary-background-hover: var(--palette-strong-1-hover);

    --button-tertiary-color: var(--white);
    --button-tertiary-background: var(--palette-strong-1);
    --button-tertiary-border: var(--palette-strong-1);
    --button-tertiary-background-hover: var(--palette-strong-1-hover);
  }
}
