@import '../../../assets/styles/index.scss';

.form {
  width: 100%;
  row-gap: rem(16);
  margin-bottom: rem(32) !important;

  .input {
    margin: 0;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
