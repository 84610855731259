@import '../../assets/styles/index.scss';

.new-header-client-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  height: auto;
  width: calc(100vw - rem(96));
  border-radius: rem(5);
  padding-left: rem(8);
  box-shadow: 0 0 rem(10) rem(3) rgba(0, 0, 0, 0.1);
  margin: rem(16) auto;
  left: 0;
  right: 0;
  background-color: var(--navbar-background);
  box-sizing: border-box;
  z-index: 10000;

  &.background-invisible {
    background-color: transparent;
  }

  &.remove-box-shadow {
    box-shadow: none;
  }

  &.on-top {
    position: relative;
    margin: 0;
    width: 100%;
    border-radius: 0;
  }

  &.remove-padding {
    padding: 0;
  }

  &.dark-mode {
  }

  .social {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: rem(40);
  }

  .items-container {
    display: flex;
    align-items: center;
    .direct-buttons {
      display: flex;
      align-items: center;
      .menu-button {
        --icon-color: var(--navbar-menu-button-color);
        background-color: transparent;
        display: none;
        border-radius: rem(4);
        justify-content: center;
        margin-right: 0;
        border: unset;

        &:focus-within {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &:active,
        &:focus-visible {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .logo {
      height: rem(40);
      padding: rem(8);
      box-sizing: border-box;
      user-select: none;
      cursor: pointer;
      border-right: 1px solid var(--gray-2);

      &.tablet-down {
        display: none;
      }
    }

    .inner {
      width: 100%;
      height: 100%;
      border-radius: rem(4);
      background-color: white;
    }

    .items {
      display: flex;
      height: rem(40);
      margin: 0;
      padding: 0;

      &.tablet-up {
        display: flex;
      }

      &.tablet-down {
        display: none;
      }
    }
  }
}

@media ($tablet-down) {
  .new-header-client-root {
    display: flex;
    justify-content: space-between;
    width: calc(100vw - rem(64));
    margin: rem(8) auto;
    padding: 0;

    .items-container {
      display: flex;
      justify-content: space-between;
      .direct-buttons {
        .menu-button {
          display: flex;
          width: rem(40);
        }
      }

      .logo {
        border-right: none;
        height: rem(40);
        padding: rem(8);
        box-sizing: border-box;

        &.tablet-up {
          display: none;
        }

        &.tablet-down {
          display: flex;
        }
      }

      .items {
        &.tablet-up {
          display: none;
        }

        &.tablet-down {
          display: flex;
        }
      }
    }
  }
}
