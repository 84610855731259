@import '../../assets/styles/index.scss';

.root {
  width: 100%;
  padding: rem(32) rem(8);
  padding-bottom: rem(48);
  box-sizing: border-box;

  .info {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}
