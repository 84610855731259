@import './../../assets/styles/index.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: rem(16);
  border-radius: rem(4);
  width: rem(150);
  height: rem(150);

  > p {
    text-align: center;
    font-size: rem(16);
    margin: 0;
    margin-bottom: rem(16);
    font-weight: 600;

    &.p2 {
      font-size: rem(48);
    }

    &.p3 {
      margin-bottom: 0;
    }
  }

  &.primary {
    background-color: var(--small-card-primary-background);
    color: var(--small-card-primary-color);
  }

  &.secondary {
    background-color: var(--small-card-secondary-background);
    color: var(--small-card-secondary-color);
  }
}

@media ($tablet-down) {
  .root {
    width: rem(100);
    height: rem(100);

    > p {
      font-size: rem(10);
      &.p2 {
        margin-bottom: rem(8);
        font-size: rem(32);
      }
    }
  }
}

@media (max-width: 450px) {
  .root {
    width: 30%;
    height: rem(75);

    > p {
      font-size: rem(10);
      &.p2 {
        margin-bottom: rem(8);
        font-size: rem(32);
      }
    }
  }
}
