html {
  [color-palette='dentist-1'],
  [color-palette='dentist-2'],
  [color-palette='dentist-3'] {
    --palette-strong-1: #563478;
    --palette-strong-1-hover: #4c296e;
    --palette-strong-2: #34669f;
    --palette-strong-2-hover: #163053;
    --palette-light-1: #7dbac9;
    --palette-light-1-hover: #469bae;
    --palette-light-2: #a8e9dd;
    --palette-light-2-hover: #74b0be;
    --palette-alternative-1: #fff6d9;
    --palette-alternative-1-hover: #e3d7ae;
  }

  [color-palette='dentist-1'] {
    --background-color: var(--palette-light-1);
    --font-color: var(--white);

    background-color: var(--background-color);
    color: var(--font-color);

    h3 {
      --font-color: var(--gray-1);

      color: var(--font-color);
    }
  }

  [color-palette='dentist-2'] {
    --background-color: var(--palette-strong-1);
    --font-color: var(--white);

    background-color: var(--background-color);
    color: var(--font-color);

    h3 {
      --font-color: var(--palette-strong-2);

      color: var(--font-color);
    }
  }

  [color-palette='dentist-3'] {
    --background-color: var(--white);

    background-color: var(--background-color);

    h3 {
      --font-color: var(--palette-strong-2);

      color: var(--font-color);
    }
  }
}
