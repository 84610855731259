@import './../../assets/styles/index.scss';

.button {
  @include h5;

  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-radius);
  margin-right: 8px;
  font-weight: 400;
  transition: background-color 0.5s;
  text-decoration: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  height: rem(32);
  padding: 0 rem(8);
  box-sizing: border-box;
  text-align: center;

  &.disabled {
    text-decoration: none;
    cursor: default;
    user-select: none !important;
    border: 1px solid var(--gray-3) !important;
    .text {
      color: var(--gray-3);
    }
  }

  &:last-child {
    margin: 0;
  }

  &.primary {
    border: 1px solid var(--button-primary-border);
    color: var(--button-primary-color);
    background-color: var(--button-primary-background);

    svg {
      --icon-color: var(--button-primary-color);
    }

    &:hover {
      background-color: var(--button-primary-background-hover);
    }

    &:active {
      background-color: var(--button-primary-background-active);
    }
  }

  &.secondary {
    border: 1px solid var(--button-secondary-border);
    color: var(--button-secondary-color);
    background-color: var(--button-secondary-background);

    --icon-color: var(--button-secondary-color);

    &:hover {
      background-color: var(--button-secondary-background-hover);
    }

    &:active {
      background-color: var(--fit-active);
    }
  }

  &.tertiary {
    border: 1px solid var(--button-tertiary-border);
    color: var(--button-tertiary-color);
    background-color: var(--button-tertiary-background);

    --icon-color: var(--button-tertiary-color);

    &:hover {
      background-color: var(--button-tertiary-background-hover);
    }

    &:active {
      background-color: var(--fit-active);
    }
  }

  &.success {
    border: 1px solid var(--primary-for-color);
    color: var(--success);
    background-color: transparent;
    border-color: var(--success);

    &:hover {
      background-color: var(--success-hover);
    }

    svg {
      fill: var(--success);
    }
  }

  &.only-text {
    border: none !important;
    min-height: unset;
    height: unset;
    background-color: transparent;

    &:hover {
      background-color: unset !important;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: var(--gray-5) !important;
    }

    .text {
      color: black;
    }

    .icon {
      display: none;
    }

    &.disabled {
      text-decoration: none;
      cursor: default;
      user-select: none !important;
      color: red !important;

      .text {
        color: var(--gray-3);
      }
    }
  }

  &.left,
  &.right,
  &.top,
  &.bottom {
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.left,
  &.right {
    @include h5;

    display: flex;
    font-weight: bold;
  }

  &.bottom,
  &.top {
    @include h5;

    display: flex;
    flex-direction: column;
    font-weight: bold;
  }

  &.right,
  &.bottom {
    :last-child {
      order: -1;
    }
  }

  &.only-icon {
    align-items: center;
    padding: rem(4);
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &:focus-visible {
    outline: 2px solid var(--primary-compete-color);
    outline-offset: 3px;
  }
}
