@import '../../../../assets/styles/index.scss';

.root {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: auto;

  &:has(.button[aria-disabled='true']) {
    background-color: var(--gray-1);

    .button[aria-disabled='true'] {
      background-color: var(--gray-1);
    }
  }

  .button {
    align-items: center;
    height: rem(24);
    width: rem(24);
    border-radius: rem(12);
    font-size: rem(12);
    line-height: rem(10);
    text-align: center;
    box-sizing: border-box;
    margin-right: 0;

    &[aria-disabled='true'] {
      border-radius: 0;
    }
  }
}
