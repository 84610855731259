@import '../../../../assets/styles/index.scss';

.root {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(7, auto);
  row-gap: rem(4);
  background-color: white;
  padding: 0;
  margin: 0;
  padding: rem(12);
  border-radius: 0 0 rem(8) rem(8);
  box-sizing: border-box;

  li.day-name {
    list-style: none;
    min-width: rem(32);
    font-size: rem(11);
    text-align: center;
    border-bottom: 1px solid var(--gray-2);
    margin-bottom: rem(4);
    padding: rem(4);
    user-select: none;
    box-sizing: border-box;
  }

  .out {
    border: none !important;

    &.selected-day {
      background-color: var(--primary-fit-color);
      color: white;
    }
  }
}
