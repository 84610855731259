@import '../../assets/styles/index.scss';

.dropdown {
  width: 100%;
  height: auto;

  span {
    color: var(--primary-compete-color);
  }

  .dropdown-input {
    align-items: center;
    min-height: rem(32);

    &:has(.icon:focus-visible) {
      &:first-child > :last-child > :last-child {
        border: rem(1) solid var(--gray-3);
        border-left: 0;
      }

      &:first-child > :last-child {
        box-sizing: border-box;
        border: 2px solid var(--primary-fit-color);
      }
    }

    > label {
      display: flex;
      margin-bottom: rem(8);
    }

    .dropdown-container {
      width: 100%;
      display: flex;
      .input {
        margin-bottom: 0 !important;
        span {
          display: none;
        }
      }

      .input > :first-child {
        cursor: pointer !important;
        height: rem(32);
        min-height: rem(32);
        background-color: var(--white);
        border-color: var(--gray-3);
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .icon {
        nav-index: 0;
        display: flex;
        align-items: center;
        width: auto;
        height: rem(32);
        border: 1px solid var(--gray-3);
        border-left: 0;
        border-radius: 0 rem(5) rem(5) 0;
        box-sizing: border-box;
        padding-right: rem(8);

        &:focus-visible {
          outline: none;
          border: 2px solid var(--primary-fit-color);
          border-left: 0;
        }

        &:hover {
          background-color: var(--white);
        }

        svg {
          animation: rotateArrowReverse 0.1s linear;
          transform: rotate(0deg);
          width: rem(16);
          height: rem(16);
          --icon-color: var(--gray-4);
        }
      }

      .icon.open {
        svg {
          animation: rotateArrow 0.1s linear;
          transform: rotate(90deg);
        }
      }
    }
  }

  .values-container {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    width: inherit;
    top: rem(32);
    background-color: white;
    border-radius: rem(5);
    margin-top: rem(4);
    box-shadow: 0 0 rem(4) 0 rgba(0, 0, 0, 0.1);

    .item-container {
      display: flex;
      align-items: center;
      margin-left: 0;
      border-bottom: rem(1) solid var(--gray-1);
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: var(--gray-1);
      }

      .dropdown-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        min-height: rem(32);
        height: rem(24);
        max-height: rem(24);
        padding-left: rem(8);
        border: 0;
        border-radius: 0;
        color: var(--gray-4);

        :last-child {
          justify-content: left;
        }
      }

      svg {
        width: rem(16);
        height: rem(16);
        margin-right: rem(8);
      }
    }

    .item-container {
      .dropdown-item:last-child {
        border-bottom: 0;
      }
    }
  }
}

@keyframes rotateArrow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotateArrowReverse {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
