@import '../../../assets/styles/index.scss';

.block {
  position: relative;
  width: 100%;
  .root {
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    backdrop-filter: blur(3px);

    .top {
      display: flex;
      width: 100%;
      height: 100%;

      .left,
      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }

      .left {
        width: 60%;
        margin-right: rem(32);

        .title,
        .subtitle {
          margin-top: 0;
        }

        .title {
          font-weight: 500;
          margin-bottom: rem(12);

          &.only-title {
            margin-bottom: rem(32);
          }
        }
      }

      .right {
        display: flex;
        justify-content: center;
        width: 50%;
        margin-left: rem(16);
        backdrop-filter: grayscale(rgb(100, 100, 100));
        object-fit: fill;
      }
    }

    .bottom {
      display: none;
    }
  }

  .landing-button-large,
  .landing-button-small {
    width: rem(320);
    margin-bottom: rem(16);
    height: rem(50);
  }
}

@media (max-width: 1024px) {
  .block {
    .root {
      display: flex;
      flex-direction: column;
      .top {
        align-items: center;
        flex-direction: column;
        .left {
          width: 100%;
          justify-content: end;
          align-items: center;
          margin-right: 0;
          .title {
            font-size: rem(32);
            text-align: center;
          }

          .subtitle {
            font-size: rem(24);
            text-align: center;
          }

          .landing-button-large {
            display: none;
          }
        }
        .right {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-left: 0;
          margin-top: rem(16);
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        justify-content: center;

        .landing-button-small {
          margin-top: rem(32);
          width: 50%;
        }
      }
    }
  }
}

@media ($tablet-down) {
  .block {
    width: 100%;
    .root {
      display: flex;
      flex-direction: column;
      .top {
        flex-direction: column;
        .left {
          width: 100%;
          justify-content: end;
          align-items: center;
          .title {
            font-size: rem(24);
            text-align: center;
          }

          .subtitle {
            font-size: rem(20);
          }

          .landing-button-large {
            display: none;
          }
        }
      }
    }
  }
}
