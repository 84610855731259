@import '../../../assets/styles/index.scss';

.root {
  display: grid;
  width: 100%;
  height: auto;
  column-gap: rem(32);
  row-gap: rem(32);
  grid-template-columns: repeat(5, 1fr);
  margin: 0;
  padding: 0;

  &.grid4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.grid3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.grid2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.grid1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media ($large-down) {
  .root {
    grid-template-columns: repeat(4, 1fr);

    &.grid4 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.grid3 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.grid2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media (max-width: 1024px) {
  .root {
    grid-template-columns: repeat(3, 1fr);

    &.grid4 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.grid3 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media ($tablet-down) {
  .root {
    grid-template-columns: repeat(2, 1fr);

    &.grid4 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media (max-width: 500px) {
  .root {
    grid-template-columns: repeat(1, 1fr);
  }
}
