@import '../../../assets/styles/index.scss';

.landing-wrapper-root {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  overflow-y: auto;

  .contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: rem(8) rem(16);
    font-size: rem(16);
    font-weight: 400;
    color: var(--primary-fit-color);

    .text {
      margin-right: rem(12);
    }

    .social {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: unset;

      &:hover {
        background-color: var(--fit-hover);
      }

      > :first-child {
        width: rem(24);
        height: rem(24);
      }
      svg {
        width: rem(24) !important;
        height: rem(24) !important;
      }
    }
  }
}

@media ($tablet-down) {
  .landing-wrapper-root {
    .contact {
      font-size: rem(24);
      font-weight: 500;

      .social {
        > :first-child {
          width: rem(32);
          height: rem(32);
        }
        svg {
          width: rem(32);
          height: rem(32);
        }
      }
    }
  }
}
