@import '../../assets/styles/index.scss';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: rem(16);

  > label {
    display: flex;
    margin-bottom: rem(8);
  }

  .required {
    display: flex;
    height: rem(0);
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    height: rem(128);
    max-height: rem(128);
    border-radius: rem(5);
    border: none;
    border: 1px solid var(--gray-3);
    font-weight: 200;
    resize: none;
    padding-left: rem(8);
    font-size: rem(16);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
    color: var(--gray-5);
    &:focus-visible {
      outline: 2px solid var(--primary-fit-color);
    }
  }

  &.error > input {
    border-color: var(--primary-compete-color);

    &:focus-visible {
      outline-color: var(--primary-compete-color);
    }
  }

  span {
    text-align: justify;
    color: var(--primary-compete-color);
    white-space: pre-line;
  }
}
