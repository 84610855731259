@import '../../../assets/styles/index.scss';

.background {
  position: absolute;
  z-index: 100000;
  width: 100vw;
  top: 0;
  left: -100vw;
  opacity: 0;
  background-color: transparent;
  animation: animateRight 0.2s alternate;

  &.opened {
    opacity: 1;
    left: 0px;
  }

  .vertical-menu {
    display: flex;
    position: relative;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 70%;
    background-color: rgba(255, 255, 255, 0.92);
    backdrop-filter: blur(rem(3));
    border-radius: 0 rem(8) rem(8) 0;
    box-shadow: rem(3) 0 rem(3) rem(2) rgba(0, 0, 0, 0.1);

    .close-header {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: rem(40);
      border-radius: inherit;

      span {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        padding-left: rem(8);
        font-weight: 200;
        font-size: rem(20);
        box-sizing: border-box;
      }

      .button {
        border: none;
        border-radius: 0;
        border-radius: inherit;
        border-bottom-right-radius: 0;
      }
    }

    .items-slide {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;

      .item-slide {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        padding-left: rem(8);
        height: rem(40);
        text-decoration: none;
        box-sizing: border-box;
        font-weight: 300;
        font-size: rem(20);
        cursor: pointer;

        &:hover {
          background-color: var(--gray-1);
        }

        &.active {
          box-shadow: 0 0 rem(5) rem(2) rgba(0, 0, 0, 0.1);
        }

        svg {
          width: rem(20);
          height: rem(20);
          margin-right: rem(16);
        }

        .counter {
          margin-right: rem(8);
        }
      }
    }
  }
}

@keyframes animateLeft {
  0% {
    left: -100vw;
  }

  100% {
    left: 0;
  }
}

@keyframes animateRight {
  0% {
    left: 0;
  }

  100% {
    left: -100vw;
  }
}
