@import '../../../assets/styles/index.scss';

.root {
  width: 100%;

  h3.title {
    font-size: rem(24);
  }

  h4.title {
    margin-left: rem(32);
    font-size: rem(20);
  }

  > .image {
    display: flex;
    width: rem(480);
    height: rem(280);
    border-radius: rem(4);
    overflow: hidden;

    &.left {
      float: left;
      margin: 0 rem(16) rem(16) 0;
    }

    &.right {
      float: right;
      margin: 0 0 rem(16) rem(16);
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &.tablet-down {
      display: none;
    }

    &.tablet-up {
      display: flex;
    }
  }

  > .paragraph {
    position: relative;
    top: rem(-8);
    color: var(--gray-5);
    margin: 0;
    line-height: rem(28);
    white-space: pre-wrap;
  }
}

@media (max-width: 1024px) {
  .root {
    > .image {
      width: rem(350);
      height: rem(200);
    }
  }
}

@media (max-width: 600px) {
  .root {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .title {
      align-self: self-start;
    }

    > .image {
      display: flex;
      justify-content: center;
      margin-right: 0;
      width: 100%;
      height: rem(200);
      float: none;

      &.left,
      &.right {
        margin: 0;
      }
      &.tablet-down {
        display: flex;
      }

      &.tablet-up {
        display: none;
      }
    }

    > .paragraph {
      text-align: justify;
    }
  }
}
