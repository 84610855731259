@import '../../assets/styles/index.scss';

.root {
  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: rem(8);

    &:has(.input:focus-within) {
      border-radius: rem(4);
      outline-offset: rem(1);
      outline: 2px solid var(--primary-fit-color);
    }

    .slash {
      display: flex;
      align-items: center;
      content: '/';
      height: rem(32);
      background-color: white;
      border: 1px solid var(--gray-3);
      border-left: 0;
      border-right: 0;
      box-sizing: border-box;
    }

    .input {
      margin: 0;
      display: flex;
      input {
        border-right: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        outline: none;
      }

      &.dd,
      &.mm {
        min-width: rem(40);
        width: rem(40);

        input {
          text-align: center;
          padding: 0;
        }
      }

      &.mm,
      &.yyyy {
        input {
          border-left: 0 !important;
          border-radius: 0;
        }
      }

      &.dd,
      &.mm,
      &.yyyy {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .date-button {
      border: 1px solid var(--gray-3) !important;
      border-left: none !important;
      width: rem(32);
      height: rem(32);
      border-radius: 0 rem(4) rem(4) 0;
    }
  }

  .date {
    width: 100%;
    max-width: rem(280);
  }
}
