@import '../../assets/styles/index.scss';

.root-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  overflow: hidden;
  z-index: 10000;

  // animation: animateBlur 3s alternate;

  &.inactive {
    backdrop-filter: blur(0);
  }

  .content {
    width: rem(800);
    height: auto;
    max-height: rem(800);
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: var(--white);
    border-radius: rem(5);
    margin: rem(16);
    animation: animate-container 1s alternate;

    .header,
    .body {
      height: auto;
      box-sizing: border-box;
      padding: rem(8);
    }

    .header {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      border-bottom: rem(1) solid var(--gray-2);

      .button {
        min-width: 0;
        min-height: 0;
        width: auto;
        height: auto;
        padding: rem(4);
        border-radius: rem(3);
        :first-child {
          display: flex;
          width: auto;
          height: auto;

          svg {
            margin-right: 0;
            width: rem(10);
            height: rem(10);
          }
        }
      }
    }

    .body {
      @include scroll;
      min-height: rem(20);
      height: auto;
      overflow-y: scroll;
      scrollbar-gutter: stable both-edges;
    }
  }
}

@media ($tablet-down) {
  .root-modal > .content {
    max-width: calc(100% - rem(64));
    max-height: calc(100% - rem(64));
  }
}

@keyframes animateBlur {
  0% {
    backdrop-filter: blur(0);
  }
  100% {
    backdrop-filter: blur(2px);
  }
}

@keyframes animate-container {
  0% {
    top: rem(400);
  }

  100% {
    top: 0;
  }
}
