@import '../../../../assets/styles/index.scss';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: rem(4) rem(8);
  box-sizing: border-box;
  border-radius: rem(8) rem(8) 0 0;

  .month {
    font-size: rem(12);
  }

  .button {
    border: none;
    height: rem(16);
    width: rem(16);
    border-radius: rem(8);
    margin: 0;

    svg {
      width: rem(10);
      height: rem(10);
    }

    &[aria-disabled='true'] {
      border: none !important;
      background-color: transparent;

      svg {
        --icon-color: transparent;
      }
    }
  }
}
