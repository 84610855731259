@import '../../assets/styles/index.scss';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: rem(16);

  > label {
    display: flex;
    margin-bottom: rem(8);
  }

  &.readonly {
    input {
      color: var(--gray-5);
      border-color: var(--gray-2);
      background-color: var(--gray-1);
      cursor: default;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .required {
    display: flex;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: rem(32);
    max-height: rem(32);
    border-radius: rem(4);
    border: rem(1) solid var(--gray-3);
    padding-left: rem(8);
    font-size: rem(16);
    font-weight: 300;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
    color: var(--gray-5);
    &:focus-visible {
      outline: 2px solid var(--primary-fit-color);
    }
  }

  &.error > input {
    border-color: var(--primary-compete-color);

    &:focus-visible {
      outline-color: var(--primary-compete-color);
    }
  }

  span {
    text-align: justify;
    color: var(--primary-compete-color);
    white-space: pre-line;
  }
}
