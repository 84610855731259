@import '../../../assets/styles/index.scss';

.landing-start-block-root {
  box-sizing: border-box;
  display: flex;
  height: auto;
  max-width: 100vw;
  padding: rem(32) rem(64);
  position: relative;
  width: 100%;

  img.background {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  .intersection {
    position: absolute;
    width: 100%;
    height: rem(60);
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(50, 50, 50, 0.9)
    );
  }
}

@media (max-width: 1024px) {
  .landing-start-block-root {
    padding: rem(16) rem(48);
  }
}

@media ($tablet-down) {
  .landing-start-block-root {
    padding: rem(16) rem(24);
  }
}
