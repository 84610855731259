@function rem($px) {
  @return calc($px/16) * 1rem;
}

@mixin header {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: rem(30);
  font-weight: 700;
}

@mixin h3 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: rem(18);
  font-weight: 400;
}

@mixin h4 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: rem(16);
  font-weight: 300;
}

@mixin h5 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: rem(14);
  font-weight: 200;
}

@mixin scroll {
  scrollbar-width: thin;
  scrollbar-color: #999 #eee;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-3);
    border-radius: 10px;
    border: 2px solid #eee;
  }
}
