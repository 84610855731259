@import '../../../../assets/styles/index.scss';

.root {
  margin-bottom: rem(32);

  .button {
    > :first-child {
      margin-right: rem(8);
      font-weight: 100;
    }
  }
}

@media ($tablet-down) {
  .root {
    .button {
      > :first-child {
        margin: 0;
      }

      > :last-child {
        display: none;
      }
    }
  }
}
