@import '../../../../assets/styles/index.scss';

.root {
  background-color: transparent;
  width: rem(200);
  max-width: rem(200);
  display: flex;

  svg {
    --icon-color: var(--map-tag-icon);
    width: rem(25);
    min-width: rem(25);
    min-height: rem(25);
    height: rem(25);
  }

  .direction {
    display: flex;
    flex-direction: column;
    padding: rem(8);
    border-radius: rem(4);
    box-sizing: border-box;
    background-color: var(--map-tag-background);
    color: var(--map-tag-color);
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }

    .text {
      margin-bottom: rem(8);
      font-size: rem(14);
    }

    .copy {
      font-weight: 500;
      margin: 0;
      margin-bottom: rem(8);
    }

    .google {
      padding: rem(8);
      box-sizing: border-box;
      img {
        width: 90%;
        height: rem(22);
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 450px) {
  .root {
    position: relative;
    flex-direction: column;
  }
}
