@import '../../assets/styles/index.scss';

.notification {
  display: flex;
  position: relative;
  border-radius: rem(8);
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: rem(2);
  color: white;
  background-color: var(--primary-compete-color);
  width: rem(16);
  height: rem(16);
  font-size: rem(12);
  box-shadow: 0 0 rem(6) rem(1) rgba(0, 0, 0, 0.1);
  transition: width 0.2s, height 0.2s;
}
