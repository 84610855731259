@import '../../assets/styles/index.scss';

.root {
  display: flex;
  align-items: center;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button {
    --button-primary-background: transparent;
    margin-right: 0;
    padding: 0;
    padding-right: rem(8);
  }

  .icon {
    --button-primary-background: transparent;

    width: rem(20) !important;
    height: rem(20) !important;
    margin-right: rem(8);
  }
}
