@import '../../../assets/styles/index.scss';

.root {
  width: 100vw;
  height: 100vh;

  .content {
    height: calc(100vh - rem(40));
    overflow-y: scroll;
    padding: rem(32);
    display: flex;
    flex-direction: column;
    margin-bottom: rem(32);
    box-sizing: border-box;

    > .title {
      margin-top: rem(32);
      color: var(--page-root-landing-title-color);
      font-weight: 400;
      font-size: rem(32);
      padding-bottom: rem(8);
      border-bottom: rem(2) solid var(--page-root-landing-heading-border-color);
    }
  }
}

@media ($tablet-down) {
  .root {
    .content {
      padding: rem(16);
      > .title {
        font-size: rem(24);
      }
    }
  }
}
