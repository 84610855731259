html {
  [color-palette='unknown'] {
    --navbar-background: var(--white);
    --navbar-menu-button-color: var(--palette-light-2);
  }

  [color-palette='doctor-1'] {
    --navbar-background: var(--palette-light-2);
  }

  [color-palette='doctor-2'] {
    --navbar-background: var(--palette-light-2);
    --navbar-menu-button-color: var(--palette-light-2);
  }

  [color-palette='doctor-3'] {
    --navbar-background: var(--palette-light-2);
    --navbar-menu-button-color: var(--palette-light-2);
  }

  [color-palette='dentist-3'] {
    --navbar-background: var(--white);
    --navbar-menu-button-color: var(--palette-light-2);
  }
}
