@import '../../assets/styles/index.scss';

.root {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  color: var(--service-card-color);
  background-color: var(--service-card-background);
  padding: rem(16);
  width: auto;
  max-width: rem(700);
  border-radius: rem(4);
  margin-bottom: rem(32);

  &.end {
    align-self: self-end;

    .buttons {
      align-self: flex-end;
    }
  }

  .title {
    margin: 0;
    font-weight: 400;
  }

  .text {
    font-weight: 200;
  }
}
