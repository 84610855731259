@import '../../../assets/styles/index.scss';

.block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: rem(16);
  column-gap: rem(16);
}

@media (max-width: 450px) {
  .block {
    display: flex;
  }
}
