html {
  // success

  --toast-background-success: var(--success-background);
  --toast-color-success: var(--success-color);
  --toast-close-button-background-hover-success: var(
    --success-background-hover
  );
  --toast-timing-success: var(--success-background-active);

  // warning

  --toast-background-warning: var(--warning-background);
  --toast-color-warning: var(--warning-color);
  --toast-close-button-background-hover-warning: var(
    --warning-background-hover
  );
  --toast-timing-warning: var(--warning-background-active);

  // error

  --toast-background-error: var(--error-background);
  --toast-color-error: var(--error-color);
  --toast-close-button-background-hover-error: var(--error-background-hover);
  --toast-timing-error: var(--error-background-active);

  [color-palette='dentist-1'],
  [color-palette='dentist-2'],
  [color-palette='dentist-3'] {
    // primary

    --toast-background-primary: var(--palette-strong-2);
    --toast-color-primary: var(--white);
    --toast-close-button-background-hover-primary: var(
      --palette-strong-2-hover
    );
    --toast-timing-primary: var(--palette-strong-1);

    // secondary

    --toast-background-secondary: var(--palette-strong-1);
    --toast-color-secondary: var(--white);
    --toast-close-button-background-hover-secondary: var(
      --palette-strong-1-hover
    );
    --toast-timing-secondary: var(--palette-light-1);
  }
}
