html {
  [color-palette='doctor-1'],
  [color-palette='doctor-2'],
  [color-palette='doctor-3'] {
    --map-tag-background: var(--palette-strong-2);
    --map-tag-color: var(--palette-light-2);
    --map-tag-icon: var(--palette-strong-2);
  }

  [color-palette='dentist-1'] {
    --layout-background: var(--white);
    --map-tag-color: var(--palette-light-2);
    --map-tag-icon: var(--palette-strong-2);
  }
}
